<template>
<div class="action-block">
  <div class="common-action yellow" @click="showModal">
    <i :class="block.icon"></i> GOAL: {{ block.options.name }}
  </div>

  <b-modal id="date-modal" v-model="isShowModal" title="Date" size="md">
    <div class="row">
      <div class="col-12">
        <TextInput v-model="block.options.name" label="Name"/>
      </div>
    </div>
    <p>
      <strong>Jump to this action when the goal conditions are met.</strong> <br>
      <strong>The person hasn't responded to the questions or data collection within the amount of time below.</strong>
    </p>
    <div class="row">
      <div class="col-4">
        <select v-model="block.options.type" class="form-control">
          <option value="Wait for">Wait for</option>
          <option value="keyword_added">Keyword Added</option>
        </select>
      </div>
      <div class="col-8">
        <div v-if="block.options.type === 'Wait for'" class="row">
          <div class="col-8">
            <select v-model="block.options.duration" class="form-control">
              <option v-for="(value, index) in durationOptions" :key="`options-${index}`" :value="index + 1">{{ index + 1}}</option>
            </select>
          </div>
          <div class="col-4">
            <select v-model="block.options.period" class="form-control">
              <option value="seconds">seconds</option>
              <option value="minutes">minutes</option>
              <option value="hour(s)">hour(s)</option>
              <option value="day(s)">day(s)</option>
              <option value="week(s)">week(s)</option>
              <option value="month(s)">month(s)</option>
              <option value="year(s)">year(s)</option>
            </select>
          </div>
        </div>
        <div v-else-if="block.options.type === 'keyword_added'" class="row">
          <div class="col-12">
            <multiselect v-model="block.options.keywords" :options="keywords" :multiple="true" track-by="id"
              label="name" placeholder="Select keywords"></multiselect>
          </div>
        </div>
      </div>
    </div>
    <template v-slot:modal-footer>
      <b-button variant="primary" @click="save">Save</b-button>
    </template>
  </b-modal>
</div>
</template>

<script>
import Multiselect from 'vue-multiselect'
export default {
  components: { Multiselect, },

  props: {
    block: {
      type: Object,
      default: null,
    }
  },

  data() {
    return {
      isShowModal: false,
      keywords: [],
    }
  },

  computed: {
    durationOptions() {
      return new Array(60)
    }
  },

  mounted() {
    if (this.block.isNewBlock) {
      this.block.isNewBlock = false
      this.showModal()
    }
  },

  methods: {
    save() {
      this.isShowModal = false
    },

    showModal() {
      this.isShowModal = true
      this.getKeywords()
    },

    getKeywords() {
      this.$store
        .dispatch('coupon/getKeywordsWithoutDemo')
        .then((keywords) => {
          this.keywords = keywords
        })
        .catch(() => {
        })
    },
  },
}
</script>